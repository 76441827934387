import {
  AppError,
  getAppStatus,
  LOAD_ERROR,
  getAppNames,
  unregisterApplication,
} from 'single-spa'
import { LayoutEngine } from 'single-spa-layout/dist/types/browser/constructLayoutEngine'
import crashScreen from '../screens/crash-screen.html'
import { captureError } from './datadog'
import { captureSentryError } from './sentry'

const CALLS_RESET_PATH_PATTERN = /^\/calls\/(genesys|nice|pilot)\/reset\/?$/i
const CALLS_CRASH_SCREEN_PATH =
  'https://assets.thriveglobal.com/reset/video/webm/60seconds/021a46d3-e0e5-450b-8b2e-fd91d85a29ff.webm'

const appErrorHandler = (layoutEngine: LayoutEngine) => {
  return (err: AppError) => {
    console.error(`Error loading ${err.appOrParcelName}:`, err)
    if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
      layoutEngine.deactivate()
      getAppNames().forEach((app) => unregisterApplication(app))

      document.body.innerHTML = crashScreen
      captureError(err, { message: `${err.appOrParcelName}: Failed to Load` })
      captureSentryError(err.appOrParcelName)

      // Display a pre-defined reset video to agents when the calls app fails to load as default crash screen is not relevant to them
      if (CALLS_RESET_PATH_PATTERN.test(window.location.pathname)) {
        window.location.href = CALLS_CRASH_SCREEN_PATH
      }
    }
  }
}

export default appErrorHandler
