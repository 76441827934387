declare global {
  interface Window {
    Sentry: any
  }
}

const SENTRY_DSN =
  'https://a5af27b4769e47f3b15b8eacb3734fb9@o59726.ingest.sentry.io/6638572'
const SENTRY_SRC = 'https://browser.sentry-cdn.com/7.9.0/bundle.min.js'

export const captureSentryError = (appOrParcelName: string) => {
  let script = document.createElement('script')
  script.onload = function () {
    window.Sentry.init({ dsn: SENTRY_DSN })
    window.Sentry.captureMessage(`${appOrParcelName}: Failed to Load`, {
      tags: {
        app: appOrParcelName,
      },
    })
  }
  script.src = SENTRY_SRC

  document.head.appendChild(script)
}
