import { isAdmin } from '../Env'

export const maybeDisableAvoDebugger = () => {
  if (isAdmin && typeof Storage !== 'undefined') {
    try {
      window.sessionStorage.setItem('disableAvoDebugger', 'true')
    } catch (e) {
      console.warn('Error setting disableAvoDebugger in sessionStorage:', e)
    }
  }
}
