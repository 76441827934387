import * as ENV from '../Env'

// Global __THRIVE__ object
declare global {
  interface Window {
    __THRIVE__: any
  }
}

const setupWindowGlobal = () => {
  ;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: ENV.appName,
    version: ENV.appVersion,
    created: ENV.appCreated,
  })
}

export default setupWindowGlobal
