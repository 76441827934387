import { captureError } from '../utils/datadog'

async function checkAuth() {
  try {
    const authCore = await System.import('@thriveglobal/thrive-web-auth-core')
    await authCore.maybeRefreshToken()
  } catch (error) {
    captureError(error)
  }
}

export default checkAuth
